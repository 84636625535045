import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
import { getArticleInfo } from '~/utils';
export default defineComponent({
    name: 'CardListMobileItem',
    props: {
        isAd: {
            default: false,
            type: Boolean,
        },
        isPartner: {
            default: false,
            type: Boolean,
        },
        isSpecial: {
            default: false,
            type: Boolean,
        },
        item: {
            required: true,
            type: Object,
        },
        withHeading: {
            default: true,
            type: Boolean,
        },
        withImage: {
            default: false,
            type: Boolean,
        },
    },
    setup(props, { root }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Article info.
         */
        const article = getArticleInfo.call(root, props.item);
        /**
         * Entry theme.
         */
        const entryTheme = computed(() => {
            if (props.isPartner) {
                return 'red';
            }
            if (props.isSpecial) {
                return 'orange';
            }
            return 'blue';
        });
        const link = computed(() => {
            const { path = '' } = article;
            if (path && path.match(/(https?:)?\/\//g)) {
                return {
                    attrs: {
                        href: path,
                        rel: 'noopener',
                        target: '_blank',
                    },
                    component: 'a',
                };
            }
            return {
                attrs: {
                    to: '/' + path,
                },
                component: 'NLink',
            };
        });
        const entries = computed(() => {
            if (props.isAd) {
                return ['Спецпроект'];
            }
            const { rubrics: entries = [] } = article;
            const output = [];
            for (const entry of entries) {
                let { title, to } = entry;
                const { name, url } = entry;
                to = to || url;
                title = title || name;
                output.push({
                    to: to.startsWith('/') ? to : '/' + to,
                    title,
                });
            }
            return output;
        });
        return {
            article,
            css,
            entries,
            entryTheme,
            link,
        };
    },
});
