// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tnHzo,.jNQtB{display:block}.tnHzo{width:calc(100% + 2px);height:50.6666666667vw;margin:-1px;background-color:#e2e2e2;border-radius:8px 8px 0 0}.tnHzo picture{width:100%;height:100%}.tnHzo picture img,.jku60{border-radius:8px 8px 0 0}.jku60{height:100%}.CMYLe{position:relative;padding:16px}._5mKrT .CMYLe{padding-top:0}.IRGUw{display:block;margin:0;color:#2d2d2d;font-size:16px;font-weight:400;line-height:22px}.IRGUw:before{position:absolute;z-index:1;top:0;left:0;width:100%;height:100%;content:\"\"}.Ag9db .IRGUw{font-family:\"Merriweather\",serif;font-size:22px;font-weight:700;line-height:28px}.jNQtB .ft2gV{position:relative;z-index:2;display:inline-block;margin-top:8px;color:#767676;font-size:13px;line-height:16px}.jNQtB ._3Jnkp{min-height:44px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "tnHzo",
	"root": "jNQtB",
	"picture": "jku60",
	"body": "CMYLe",
	"withHeading": "_5mKrT",
	"title": "IRGUw",
	"withImage": "Ag9db",
	"advertiser": "ft2gV",
	"heading": "_3Jnkp"
};
module.exports = ___CSS_LOADER_EXPORT___;
