import { computed, useContext, defineComponent, ref, useCssModule } from '@nuxtjs/composition-api';
import { isFlutter, parseAdfoxHTML } from '~/utils';
export default defineComponent({
    name: 'CardListMobileItemAdfox',
    props: {
        config: {
            required: true,
            type: Object,
        },
        withImage: {
            default: false,
            type: Boolean,
        },
    },
    emits: ['complete'],
    setup(_, { emit }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const context = useContext();
        const rootEl = ref();
        const adfoxArticle = ref();
        const shouldRenderAds = computed(() => !isFlutter(context.$device.userAgent));
        function onComplete({ error = {}, state }) {
            emit('complete', { error, state });
            if (error?.message || !rootEl.value) {
                return;
            }
            const adfoxEl = rootEl.value.querySelector('[id^="adfox"]');
            if (!adfoxEl && !state.isRendered) {
                return;
            }
            // Parse HTMl in Adfox container
            adfoxArticle.value = parseAdfoxHTML(adfoxEl);
        }
        return {
            css,
            onComplete,
            rootEl,
            adfoxArticle,
            shouldRenderAds,
        };
    },
});
