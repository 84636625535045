import { computed, defineComponent, onMounted, reactive, ref, useCssModule, watch } from '@nuxtjs/composition-api';
import { DEFAULT_OPTIONS } from '.';
import { ADFOX_CODES_DESKTOP } from '~/components/Adfox';
export default defineComponent({
    name: 'CardListMobile',
    props: {
        data: {
            default: () => [],
            type: Array,
        },
        options: {
            default: () => DEFAULT_OPTIONS,
            type: Object,
        },
        size: {
            required: true,
            type: Object,
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const rootEl = ref();
        /**
         * Current options
         */
        const currentOptions = computed(() => {
            return {
                ...DEFAULT_OPTIONS,
                ...props.options,
            };
        });
        /**
         * Is partner
         */
        const isPartner = computed(() => currentOptions.value.type === 'partner');
        /**
         * Is special
         */
        const isSpecial = computed(() => currentOptions.value.type === 'special');
        /**
         * Adfox items
         */
        const adfoxItems = reactive({
            '0': {
                config: ADFOX_CODES_DESKTOP.partner_1,
                isVisible: true,
            },
            '1': {
                config: ADFOX_CODES_DESKTOP.partner_2,
                isVisible: true,
            },
            '2': {
                config: ADFOX_CODES_DESKTOP.partner_3,
                isVisible: true,
            },
            '3': {
                config: ADFOX_CODES_DESKTOP.partner_4,
                isVisible: true,
            },
        });
        /**
         * Adfox visible items count
         */
        const adfoxVisibleItemsCount = computed(() => {
            return Object.values(adfoxItems).filter((item) => item.isVisible).length;
        });
        /**
         * Current items
         */
        const currentItems = computed(() => {
            let itemsLength = currentOptions.value.length;
            if (isPartner.value) {
                itemsLength = itemsLength - adfoxVisibleItemsCount.value;
            }
            return props.data?.slice(0, itemsLength).map((item) => {
                const article = ('data' in item ? item.data : item);
                return article;
            });
        });
        /**
         * Handles completing adfox
         */
        function onAdfoxComplete(adfoxItem, { error = {}, state }) {
            if (error?.message || (state.isCompleted && !state.isRendered)) {
                adfoxItem.isVisible = false;
            }
        }
        /**
         * Shows card header block
         */
        function shouldShowHeading(item) {
            return item.sections.length > 0 || item.isExternalLink || item.isGallery || isPartner.value || isSpecial.value;
        }
        function showWaterfall() {
            const event = new CustomEvent('togglePullout', {
                detail: 'waterfall',
            });
            window.dispatchEvent(event);
        }
        onMounted(() => {
            watch(currentItems, () => {
                if (currentItems.value.length && rootEl.value?.$el) {
                    rootEl.value.$el.style.minHeight = 'unset';
                }
            }, { immediate: true });
        });
        return {
            adfoxItems,
            adfoxVisibleItemsCount,
            css,
            currentItems,
            currentOptions,
            isPartner,
            isSpecial,
            onAdfoxComplete,
            rootEl,
            shouldShowHeading,
            showWaterfall,
        };
    },
});
