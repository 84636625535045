import { render, staticRenderFns } from "./CardListMobileItem.vue?vue&type=template&id=68f0d031&"
import script from "./CardListMobileItem.vue?vue&type=script&lang=ts&"
export * from "./CardListMobileItem.vue?vue&type=script&lang=ts&"
import style0 from "./CardListMobileItem.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/var/www/src/components/BaseImage/BaseImage.vue').default,CardHeader: require('/var/www/src/components/CardHeader/CardHeader.vue').default,AdfoxAdvertiser: require('/var/www/src/components/AdfoxAdvertiser/AdfoxAdvertiser.vue').default})
