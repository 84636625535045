// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".At\\+9B{position:relative;min-height:300px;margin:0 8px;background:#fff;border-radius:8px}@media screen and (min-width:400px){.At\\+9B{margin:0 16px}}.yS78d{padding:0;border:1px solid #e2e2e2;margin:0;border-radius:8px;list-style:none}.In-h9{position:relative;cursor:pointer}.In-h9:after{position:absolute;bottom:0;left:16px;width:calc(100% - 32px);height:1px;background:#e2e2e2;content:\"\"}.In-h9:last-child:after{content:none}.JY6cH{padding:24px 16px}.Ce43S{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "At+9B",
	"list": "yS78d",
	"item": "In-h9",
	"read": "JY6cH",
	"loader": "Ce43S"
};
module.exports = ___CSS_LOADER_EXPORT___;
