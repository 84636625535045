// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5bodJ{position:relative;min-height:104px}._5bodJ ._5cHS6{position:absolute;bottom:16px;left:16px;width:calc(100% - 32px)}._5bodJ ._5cHS6>:last-child{margin-top:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_5bodJ",
	"loader": "_5cHS6"
};
module.exports = ___CSS_LOADER_EXPORT___;
